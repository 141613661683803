import React from "react"
import Layout from "../../components/layout"

import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Products from "../../components/peaksuiteProducts"
// import docgen from "../../images/DocgenpeakSUITE.mp4"
const Banner = () => (
  <div>
    <div className="hero-banner peaksuite-wrap-hero">
      <div className="container">
        <div
          className="hero-banner-content text-center"
          style={{ padding: "50px 0px" }}
        >
          <div
            className="hero-title-wrap text-center"
            style={{ background: "#fff" }}
          >
            <ScrollAnimation animateIn="fadeInLeft" initiallyVisible={true}>
              <Link aria-label="link" to="/peaksuite/peaksuite-calendar/">
                <StaticImage
                  placeholder="blurred"
                  imgClassName="img-fluid"
                  src="../../../static/assets/peaksuite-Chat Tool.png"
                  alt="peakSUITE CHAT TOOL"
                  width={400}
                  loading="lazy"
                  layout="constrained"
                />
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  </div>
)
class ProductPeakSuite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "peakSuite | basecamp",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    localStorage.setItem("title", this.state.title)
  }
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="peakSUITE Chat Tool | Custom Add-Ons for Quickbase"
          description="Empower users within a team to send messages and notifications to anyone within Quickbase without a confusing mess of emails. Learn more."
        />
        <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
          <section className="component pt-4 pb-0">
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-12 peakSuite-product">
                  <div className="row">
                    <div className="col-md-7 col-sm-12">
                      <iframe
                        src="https://promo.com/embed/6091d98001f5710c0b39389d?ratioAspect=wide"
                        frameBorder="0"
                        width="100%"
                        height="400"
                        className="mr-3 mb-3"
                        style={{ boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)" }}
                        title="basecamp"
                      />
                    </div>
                    <div className="col-md-5 col-sm-12 align-self-center">
                      <p>
                        <span className="basecamp-color">
                          peakSUITE | Chat Tool{" "}
                        </span>
                        Empower users within a team to send messages and
                        notifications to anyone within Quickbase without having
                        to message or email them. Share room changes and updates
                        within seconds right on the Quickbase platform. No
                        changing apps. No switching platforms.
                      </p>
                      <p>
                        Add hubs and segment them into teams within your
                        organization while keeping track and staying ahead of
                        changes with Chat Tool. And get the insight you need to
                        address your staff effectively and efficiently.
                      </p>
                      <p>
                        Chat Tool lets you save messages, invite others to hubs,
                        establish user-based access, and experience a range of
                        customizable options. Logins are time-sensitive and
                        remain valid for up to 8 hours. All messages stay on the
                        platform, keeping your communications secure.
                      </p>
                    </div>
                  </div>
                  {/* <div className="list-wrap d-block">
                    <p>What can this application do?</p>
                    <ul className="list-unstyled basecamp">
                      <li>Reporting and notifications for managers</li>
                      <li>Track tasks and who they are assigned to</li>
                      <li>
                        Easily see which tasks are complete and which tasks are
                        outstanding
                      </li>
                      <li>
                        Allow employees to flag tasks that require assistance to
                        complete, or are blocked
                      </li>
                      <li>
                        Record meeting notes in one place for easy reference
                      </li>
                      <li>
                        Implement daily summary notes for employees to document
                        their productivity
                      </li>
                      <li>
                        Maintain a list of your employees, their contact
                        information, and the company inventory they are using at
                        their home office
                      </li>
                      <li>
                        Provide employees with a ‘who to call’ list so they will
                        know who to reach out to if they have certain issues
                      </li>
                      <li>
                        Keep employees up to date with company news and
                        announcements with peakSUITE | Echo, a tool for company
                        announcements built right into their dashboard
                      </li>
                      <li>
                        Maintain a list of your clients and their contact
                        information if desired
                      </li>
                      <li>Store documents for easy reference</li>
                    </ul>
                  </div> */}

                  {/* <p>
                    Managers see a real time overview of work in progress,
                    complete, and outstanding on their dashboard. Stay connected
                    and understand what your employees are working on and where
                    they need help. Fully customizable to meet your
                    company&#39;s specific needs in this rapidly changing time.
                  </p> */}
                  <div className="text-center mt-5">
                    <Link
                      to="/contact/"
                      onClick={this.handleSubmit}
                      className="btn btn-primary btn-arrow echo-bg"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row peaksuite-products mt-5">
                <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
                  {" "}
                  <div className="return-btn-basecamp">
                    <Link
                      to="/peaksuite/"
                      className="btn btn-primary-outline px-3"
                      style={{
                        color: "#224a9a",
                        border: "1px solid #224a9a",
                        fontSize: "18px",
                      }}
                    >
                      <i className="fa fa-caret-left mr-2" />
                      Return
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-9 col-12 prod-order-1">
                  <Products />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ProductPeakSuite
